body {
  margin: 0;
  font-family:Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Sora-Medium";
  src: local("Sora-Medium"),
   url("./assets/Sora-Medium.ttf") format("truetype");
  font-weight: bold;
  }

  @font-face {
    font-family: "Sora-Regular";
    src: local("Sora-Regular"),
     url("./assets/Sora-Regular.ttf") format("truetype");
    font-weight: medium;
    }
  
    @media (prefers-color-scheme: dark) {
      html {
        color-scheme: light;
      }
    }