@media screen and (max-width: 1709px) {

  .mainPav2 {
    min-width: 450PX;
    display: fixed;
  }

  .sugedoRight {
    /* min-width: 720px;
    margin: 0 20px 20px 0; */
  }

}

@media screen and (max-width: 1000px) {
  .servicesDotPos {
    position: absolute;
    right: 5%;
    top: 16% !important;
    bottom: unset !important;
    margin: 0 0 0 70px;
  }

  .viskas>div>div {
    width: 100%;
  }


  .eight {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
  }

  .eight2 {
    right: 50%;
  }

  .newsName {
    font-size: 35px;
    text-align: center;
  }

  .naujienaData {
    text-align: center;
    /* margin: 20px 0 20px 0; */
  }

  .naujienaPavadinimas {
    text-align: center;
    /* margin: 20px 0 20px 0; */
  }


  .naujienaTekstas2 {
    text-align: center;
    /* margin: 20px 0 20px 0; */
    /* width: 80%; */
  }

  .naujienaTekstas {
    text-align: center;
    /* margin: 20px 0 20px 0; */
    /* width: 80%; */
  }

  .archyveRight {
    width: unset;
  }

  .archyveRightV {
    width: unset;
    margin: -43px 0;
    background-color: #ffffff;
    padding: 50px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    /* z-index: 2; */
  }

  .newsTextCont {
    align-items: center;
    padding: 50px;
    background-color: white;
  }

  .naujienaTekstas2 {
    width: unset;
  }

  .newsTextContV {
    align-items: center;
    margin: 20px 0 20px 0;
  }

  .pipes1 img {
    max-height: 750px;
    object-fit: cover;
  }

  .pipes3 img {
    max-height: 100%;
    object-fit: cover;
    height: unset;
  }

  .pipes3 {
    width: 100%;
  }

  .produktas_container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    row-gap: 30px;
  }

  .produkas {
    width: unset;
    max-width: unset;
    margin: 0 20px;
  }

  .naujienaTekstas4{
    text-align: center;
  }

  .naujienaTekstas24{
    text-align: center;
  }

  .lentele.naujienos {
    flex-direction: column;
    width: unset;
  }

  .pipeContent {
    position: unset;
  }

  .newsContainer3 {
    width: 90%;
  }

  .serviceName {
    font-size: 10vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 6%;
  }

  .servicesDotPos {
    bottom: 8%;
    position: absolute;
    top: unset;
  }

  .homeRightBottom {
    flex-wrap: wrap;
  }

  .navContainer {
    display: none;
  }

  .navBar {
    justify-content: center;
    padding: 20px 0 20px 0;
    background-color: #f0eee7;
  }

  .ham {
    display: flex;
    position: absolute;
    left: 30px;
  }

  .mobileTop {
    position: relative;
    display: flex;
    padding: 20px 0 20px 0;
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
  }

  .mobileClose {
    position: absolute;
    left: 30px;
    font-size: 20px;
  }

  .logoMenuMob img {
    width: 180px;
  }

  .logoMenuMob {
    justify-content: center;
    display: flex;
    margin: 0 20px 0 30px;
  }

  .navMobText {
    margin: 20px 0 20px 0;
    color: black;
  }

  .navItemMob {
    color: black;
    text-decoration: none;
    margin: 16px;
    font-size: larger;
    font-weight: 500;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .navItemMob1 {
    color: black;
    text-decoration: none;
    margin: 16px;
    font-size: larger;
    font-weight: 500;
  }

  .taskas {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dotNavBar {
    height: 75px;
    width: 75px;
    background-color: #db643e;
    border-radius: 50%;
    position: absolute;
    top: 10;
    z-index: 5;
    left: 50%;
    mix-blend-mode: hard-light;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .dotNavBar:active {
    background-color: #db643e;
  }

  .mobileNav {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: white;
    position: fixed;
    background-image: url("assets/wire.png");
  }

  .mobileNavigationList {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .naujienlaiskisCont {
    position: absolute;
    background-color: #f4b050;
    z-index: 1;
    width: 71%;
    height: 43px;
    border-radius: 10vw;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .naujienlaiskisBtnTxt {
    display: none;
  }

  .naujienlaiskisBtn {
    right: 5vw;
  }

  .footerMob {
    display: flex;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 30px 0;
  }

  .footerTextMob {
    color: #ffffff;
    margin: 20px 0 20px 0;
  }

  .footerItem {
    color: white;
    text-decoration: none;
    margin: 20px;
    font-size: larger;
    font-weight: 500;
  }

  .footerTelMob {
    color: black;
    background-color: #f5b150;
    padding: 10px 40px;
    border-radius: 10vw;
    margin: 10px 0 10px 0;
  }

  .footerMob.neGridFoot {
    display: flex;
  }

  .socIcon img {
    padding: 30px;
  }

  .socIcon {
    display: flex;
    justify-content: center;
  }

  .footer {
    display: none;
  }

  .garantijaPoints {
    flex-direction: column;
    align-items: center;
    display: flex;
    margin: 30px 0 0 0;
  }

  .centerContainer {
    flex-direction: column;
    align-items: center;
  }


  .main_dot {
    width: 80px;
    height: 80px;
  }

  .dotTxt {
    font-size: 10px;
  }

  .homeLeftPav {
    width: 100%;
    justify-content: center;
  }

  .mainDotPos {
    right: 0px;
  }

  .mainPavTxt {
    font-size: 10px;
  }

  /*////about////*/

  .aboutTxt {
    width: 25ch;
    margin: 20px 0 40px 0;
  }

  .aboutTxt2 {
    font-size: 15px;
    line-height: 24px;
  }

  .aboutName {
    font-size: 35px;
  }

  .pipes2 {
    display: none;
  }

  .pipes22 {
    display: flex;
    position: relative;
  }

  .pipes22 img {
    width: 100%;
  }

  .aboutMobContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .aboutMobTxtContainer {
    background-color: #2f8b50;
  }

  .aboutMobName {
    font-size: 35px;
  }

  .aboutMobTxt {
    padding: 15px;
    font-size: 15px;
  }

  .aboutMobBtn {
    position: absolute;
    border: 0px;
    border-radius: 40px;
    padding: 13px;
    width: 40%;
    font-size: small;
    background-color: #f5b150;
    cursor: pointer;
    z-index: 1;
    top: 88vh;
    color: black;
  }

  .aboutMobBtnCont {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aboutDot {
    height: 75px;
    width: 75px;
    background-color: #db643e;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aboutDotTxt {
    text-align: center;
    font-size: 11px;
    padding: 10px;
  }

  .aboutDotPos {
    position: absolute;
    z-index: 3;
    top: 15%;
    right: 5%;
    display: flex;
    justify-content: flex-end;
  }

  /*////////////*/

  .vinis {
    display: none;
  }

  .vinisMob {
    display: flex;
    position: absolute;
  }

  .garantija_points {
    z-index: 1;
    text-align: center;
    font-size: 15px;
    width: 80%;
  }

  .punktasG {
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 40px 0 40px 0;
  }

  .garantijaTxt {
    font-size: 15px;
    width: 100%;
  }

  /*///sugedo///*/

  .sugedoRight {
    min-width: 100%;
    height: 100%;
    border-radius: 0;
    margin: 0;
    min-height: 90vh;
  }

  .sugedoContainer {
    padding: 20px 10px;
  }

  .iskvietimasContainer {
    flex-direction: column;
    align-items: center;
    width: unset;
  }

  .iskvietimasLeft {
    width: 100%;
    margin: unset;
  }

  .iskvietimasDataCont {
    flex-direction: column;
    margin: 0;
    align-items: center;
  }

  .modalNavigation {
    display: none;
  }

  .calendarContainer {
    width: 90%;
    max-height: 100%;
  }

  .iskvietimasRight {
    width: 100%;
    margin: unset;
  }

  .laikasBtn {
    margin: 10px 0;
    width: 40%;
  }

  .iskvietimasLaikas {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .imgRemoveCont {
    width: 100%;
    justify-content: space-evenly;
  }

  .imgModal img {
    width: 80%;
  }

  .iskvietimasPriceMob {
    display: flex;
    flex-direction: row;
    margin: 30px 0 30px 0;
  }

  .iskvietimasPrice {
    display: none;
  }


  .newsRight {
    padding: 60px;
  }
}

@media screen and (max-width: 767px) {


  .btnKviestihome {
    margin: unset;
  }

  .infoLentele {
    flex-direction: column !important;
  }

  .ManoUzsakymas {
    margin: 40px !important;
  }

  .modalInfo {
    width: 100%;
  }

  .veidasMob {
    display: none !important;
  }

  .popUpClose {
    z-index: 2;
  }

  .centerContainer {
    height: unset;
  }

  .paveikslelis {
    width: unset !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column-reverse !important;
    height: unset !important;
    max-width: 60% !important;
  }

  .avarineRight {
    width: 100%;
    border-radius: 0;
    margin: unset;
    display: flex;
    align-items: center;
    height: 90vh;
  }

  .irengimasRight {
    width: 100%;
    border-radius: 0;
    margin: unset;
    display: flex;
    align-items: center;
    height: 90vh;
    max-height: unset;
  }

  .avarineTxtCont {
    width: 85%;
  }

  .txt {
    font-size: 22px;
  }

  .btnCall {
    width: 50%;
  }

  .irengimasBtn {
    width: 90%;
  }

  .irengimasTxtCont {
    width: 100%;
  }

  .irengimasTxt {
    font-size: 19px;
  }

  .irengimasTel {
    font-size: 27px;
  }

  .sugedoRight {
    width: 90%;
    max-height: unset;
    overflow: unset;
  }

  .sugedoBtn {
    width: 90%;
  }

  .navBar.Sugedo {
    background-color: #f5b150;
  }

  .column {
    background-color: #f5b150;
    margin-bottom: 5px;
  }

  .column2 {
    background-color: #dc643e;
    margin: 0 5px 5px 5px;
  }

  .column3 {
    background-color: #727bdb;
    z-index: 3;
  }

  div#Services {
    margin: -20px 0 0 0;
  }

  .homeRightTop {
    display: none;
  }

  .garantijaImg {
    height: 50%;
    top: 0;
  }

  .socIcon {
    width: 20%;
  }

  .servicesPrice {
    flex-wrap: wrap;
    justify-content: center;
  }

  .servicesPriceLeft {
    border-radius: 500px;
    padding: 40px;
    justify-content: space-between;
    margin: 30px;
  }

  .eurCont {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .fiksuotas {
    text-align: center;
    margin-top: 10px;
  }

  .ieskote {
    align-items: center;
  }

  .servicesRight {
    text-align: center;
  }

  .servicesPriceRight {
    text-align: left;
    margin: 30px 30px 30px 30px;
  }

  .servicesIrBotRight {
    display: none;
  }

  .serevicesIrLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #f0eee7;
    margin: 20px;
    z-index: 3;
    height: 100%;
    margin-top: 300px;
    padding: 20px 0 20px 0;
  }

  .contentContainer1 {
    align-items: center;
  }

  .servicesMainTxtCall {
    display: none;
  }

  .irengimasCall {
    display: flex;
    align-items: center;
  }

  .servicesIrRight {
    display: flex;
    position: absolute;
  }

  .serviceIrTxt {
    text-align: center;
    width: 90%;
  }

  .vinis {
    width: 10vw;
  }

  .vinisTxt {
    font-size: 3vw;
  }

  .irengimasName {
    font-size: 3.5vw;
  }

  .mainPav {
    min-width: 302px;
  }

  .pavCont {
    margin: unset;
  }

  .mainPavTxt {
    font-size: 9px;
  }

  .kasAtsitikoMb {
    display: flex;
    font-size: 45px;
    justify-content: center;
    margin: 20px 0 20px 0;
  }

  .eight {
    right: unset;
    transform: unset;
  }

  .eighttxt {
    font-size: 240px;
    z-index: 0;
    color: #f0eee7;
  }

  .newsRight {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    z-index: 2;
    padding: 20px 0 20px 0;
    margin-top: 100%;
    position: relative;
  }

  .newsImg {
    display: flex;
    position: absolute;
  }

  .aboutContainer {
    display: none;
  }

  .newsContainer {
    flex-direction: column;
    display: flex;
    align-items: center;
  }


  .newsContainerV {
    flex-direction: column;
    display: flex;
    align-items: center;
    /* width: unset; */
    margin: 50px;
  }

  .pipes1V {
    width: unset;
    z-index: 3;
    width: 300px;
    height: 300px;
  }

  .pipes1V img {
    object-fit: unset;
  }

  .product_photo {
    width: unset;
  }

  .archyvasImgV {
    width: unset;
  }



  .archyvasImg {
    width: unset;
  }

  .newsContainer:nth-child(even) {
    flex-direction: column;
  }

  .footerTelMob a {
    text-decoration: none;
    color: white;
  }

  img.mainPav {
    max-height: 90vh;
    width: auto;
    max-width: 8%;
    height: auto;
    max-height: 90vh;
  }
}

@media screen and (max-width: 1000px) {
  .kaire1 {
    display: none !important;
  }

  .sugedoRight {
    -ms-overflow-style: none;
    scrollbar-width: none;

  }

  .homeRightBottom {

    padding-bottom: 20px;
  }

  .sugedoContainer {
    padding: 20px 0px;
    margin: 0px 40px;
  }

  .iskvietimasProblema {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 0 20px 0;
    width: 100%;
  }

  .iskvietimasSmallName {
    font-size: 15px;
    margin: 40px 0 0 5px;
    /* text-align: left!important; */
    align-content: left;
    /* align-items: start; */
    /* display: flex; */
    left: 1;
    align-self: baseline;
  }

  .problema_detaliau {
    background-color: #f0eee7;
    border: 0;
    border-radius: 30px;
    font-size: 16px;
    height: 181px;
    margin-top: 10px;
    padding: 20px 20px 20px 20px;
    resize: none;
    width: 89%;
    /* flex: revert-layer; */
    /* display: flex; */
  }

  .kaire {
    height: unset !important;
    width: unset !important;
  }

  .kaire1 {
    height: unset !important;
    width: unset !important;
  }

  .desine {
    height: unset !important;
    width: 100% !important;

  }



  .mainPav {
    width: 100%;
  }


}

@media screen and (max-height: 700px) {
  .homeRightTop {
    font-size: 45px !important;
    font-weight: 500;
    position: relative;
    margin: auto !important;
    padding: 0px 0px 20px 18px;

  }

  .irengimasRight {
    max-height: 90vh !important;
  }

  .sugedoRight.sugedoFix {
    height: unset;
  }

  .pav {
    font-size: 35px;
  }
}


element.style {}

html[native-dark-active] .problema_detaliau {
  background-color: var(--native-dark-bg-color) !important;
  filter: brightness(1.06);
}

html[native-dark-active] .issikviestiBotBtn {
  color: var(--native-dark-font-color);
  background-color: var(--native-dark-bg-color) !important;
  filter: brightness(1.06);
}

@media screen and (max-width: 1000px) {
  .sugedoContainer {
    padding: 20px 0px;
    margin: 0px 25px;

  }

  .column3 {
    margin-bottom: 30px;
  }

  .sugedoRight.sugedoFix {
    height: unset !important;
  }

  .iskvietimasPriceLeft {
    padding: 25px 20px 25px 20px;
  }


}

@media screen and (max-width: 420px) {

  .prod_btn_container {
    width: 100%;
    justify-content: end;
    display: flex;
    position: absolute;
    bottom: 16px;
    right: 50%;
    transform: translateX(25%);
  }
}