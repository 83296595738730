@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");

.font-face-gm {
  font-family: "Sora-Regular.ttf" !important;
}

:root {
  color-scheme: only light !important;
  --main-background: #f0eee7 !important;
  --main-color: #dc643e !important;
  --main-choose: #fbb72e !important;
  --main-inactive: grey !important;
  --main-white: white !important;
  --place-holder-clr: black !important;
  font-family: Roboto !important;
  --native-dark--main-background: var(--main-background) !important;
  --native-dark--main-color: #dc643e !important;
  --native-dark--main-choose: #fbb72e !important;
  --native-dark--main-inactive: grey !important;
  --native-dark--main-white: #f0eee7 !important;
  --native-dark--place-holder-clr: black !important;
  --native-dark-bg-image-filter: #6a5a21;
  --native-dark-opacity: unset;
  --native-dark-bg-color: #f0eee7;
  --native-dark-bg-image-color: rgba(0, 0, 0, 0);
  --native-dark-visited-link-color: #000000;
  --native-dark-link-color: #000000;
  --native-dark-font-color: black;
  --native-dark-border-color: unset;
  --html-native-dark-active: black;
}

body {
  background: var(--main-background) !important;
  font-family: "Sora-Regular" !important;
  /* max-width: 1920px; */
  appearance: none;
}

/* DARK MODE */

html[native-dark-active] .servicesPriceLeft {
  background-color: var(--main-background) !important;
}

html[native-dark-active][native-dark-active] div#root {
  background: var(--main-background) !important;
}

html[native-dark-active][native-dark-active] .servicesPriceLeft {
  background-color: var(--main-background) !important;
}

html[native-dark-active] .navBar {
  background: var(--main-background) !important;
}

/*CURSOR 2022-11-08*/

/* * {
  cursor: none;
} */

.cursor-dot,
.cursor-dot-outline {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.cursor-dot {
  width: 40px;
  height: 40px;
  background-color: var(--main-color) !important;
  mix-blend-mode: hard-light;
  z-index: 100000;
}

.cursor-dot-outline {
  width: 40px;
  height: 40px;
  background-color: rgba(229, 128, 128, 0.5);
}

/* OLD CURSOR*/
/* .cursor {
  width: 26px;
  height: 26px;
  background-color: #DB643E !important;
  mix-blend-mode: hard-light;
  border-radius: 50%;
  z-index: 1000;
  position: absolute;
} */
/* 
.cursorHover {
  width: 40px;
  height: 40px;
  background-color: #db643e;
  mix-blend-mode: hard-light;
  border-radius: 50%;
  z-index: 1000;
  position: absolute;
}

.cursor-dot {
  background-color: #db643e !important;
  width: 26px !important;
  height: 26px !important;
  mix-blend-mode: hard-light;
  z-index: 100;
}

.cursor-border {
  display: none;
}

.hover .cursor-dot {
  width: 50px !important;
  height: 50px !important;
} */

html {
  scroll-behavior: smooth;
}

.ham {
  display: none;
}

.navContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.mobileNav {
  display: none;
}

.navBar {
  line-height: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: #f0eee7 !important;
  z-index: 4;
  position: sticky;
  /* width: 100%; */
  top: 0;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.navItem {
  color: black;
  text-decoration: none;
  margin: 30px;
  font-size: larger;
  font-weight: 500;
}

.navLogo {
  /* width: 180px; */
  /* height: 40px; */
  margin: 0px 20px 0px 30px;
}

.navLogo img {
  width: 180px;
}

.mainPav {
  /* max-width: 652px; */
  /* max-width:70%; */
  /* height: 100%; */
  margin-bottom: -6px;
  /* width: 100%; */
  /* min-width: 389px; */
}

.mainPav2 {
  /* min-width: 603px; */
  margin-bottom: -6px;
  width: 100%;
}

.mainPavTxt {
  position: absolute;
  width: 10px;
  margin: unset;
  font-size: 12px;
}

.centerContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #f0eee7 !important;
  align-items: flex-start;
  /* height: 100vh; */
}

.main_dot {
  height: 115px;
  width: 115px;
  background-color: #2f8b50 !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainDotPos {
  position: absolute;
  bottom: 40px;
}

.txtCont {
  width: 69px;
  height: 69px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dotTxt {
  text-align: center;
  font-size: 15px;
}

.homeLeftPav {
  width: 100%;
  max-width: 90%;
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: flex-end;
  /* height: 100% !important; */
}

.kasAtsitikoMb {
  display: none;
}

.pavCont {
  margin: 0 0 0 85px;
}

/* .viskas{
  position: absolute;
  margin-top: 10vh;
  margin-top: 10vh;
} */

.viskas>div {
  display: flex;
  justify-items: center;
  flex-wrap: wrap;
}

.viskas>div>div {
  width: 50%;
}

.contentContainer {
  display: flex;
  height: 100%;
  width: 90%;
  margin: auto;
  max-width: 600px;
  flex-direction: column;
  justify-content: center;
}

.contentContainer1 {
  display: flex;
  height: 100%;
  width: 90%;
  margin: auto;
  max-width: 600px;
  flex-direction: column;
  justify-content: center;
}

/*////////////HOME////////////*/

.homeLeft {
  width: 40%;
  max-width: 90%;
  /* place-self: flex-start; */
  display: flex;
  margin: 0 20px 20px 20px;
  justify-content: flex-start;
  /* height: 100%; */
  /* position: fixed; */
}

.homeRight {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  /* width: 60% !important; */
  padding-right: 20px;
}

.homeRightTop {
  font-size: 80px;
  font-weight: 500;
  position: relative;
  /* margin: 30px 0 0px 0; */
  padding: 30px;
}

.homeRightBottom {
  display: flex;
  justify-content: center;
  flex: 1 1;
  margin-right: auto;
  /* flex-wrap: wrap; */
}

.homeRightBottom>div {
  width: 190px !important;
  padding: 30px;
}

.column {
  text-align: left;
  border-radius: 20px;
  justify-content: center;
}

.column:hover {
  background-color: #f5b150 !important;
}

.column2 {
  text-align: left;
  border-radius: 20px;
  justify-content: center;
}

.column2:hover {
  background-color: #dc643e !important;
}

.column3 {
  text-align: left;
  border-radius: 20px;
  justify-content: center;
}

.tel {
  font-size: 40px;
  margin: 10px 0 0 0;
  font-weight: 100;
}

.column3:hover {
  background-color: #727bdb !important;
}

.homeBtn {
  border: 1px solid black;
  border-radius: 40px;
  padding: 13px;
  width: 100%;
  font-size: large;
  background-color: unset !important;
  margin: 50px 0 0 0;
  /* cursor: none; */
  color: black;
}

/* Pelė */
/* .homeBtn {
  border: 1px solid black;
  border-radius: 40px;
  padding: 13px;
  width: 100%;
  font-size: large;
  background-color: unset !important;
  margin: 50px 0 0 0;
  cursor: none;
  color: black;
} */

.btnCntr {
  margin-top: 15px;
}

.text {
  height: 60%;
}

/*/////////////////////////*/

/*///////////MODAL NAV///////////*/

.modalNavigation {
  margin: 8px 24px 25px 25px;
  left: 10px;
  top: 10px;
  position: absolute;
}

.modalNavigationTxt {
  font-size: 14px;
}

.kelias {
  padding: 0 7px;
}

/*/////////////////////////////////*/

/*////////////AVARINE////////////*/

.avarineLeft {
  width: 40%;
  max-width: 90%;
  /* place-self: flex-start; */
  display: flex;
  /* height: 100%; */
}

.avarineRight {
  border-radius: 20px;
  width: 100%;
  max-width: 800px;
  background-color: #727bdb !important;
  /* display: flex; */
  /* flex-direction: column; */
  justify-content: flex-start;
  margin: 0 20px 40px 20px;
  position: relative;
}

.avarineContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  /* margin: 40px 0 40px 0; */
}

.pav {
  font-weight: 200;
  font-size: 50px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dot {
  height: 75px;
  width: 75px;
  background-color: #f5b150 !important;
  border-radius: 50%;
  position: absolute;
}

.greiciau {
  z-index: 1;
  position: relative;
}

/* Pelė */
.btnCall {
  border: 0px;
  border-radius: 40px;
  padding: 13px;
  width: 30%;
  font-size: large;
  background-color: #f5b150 !important;
  margin: 50px 0 0 0;
  position: relative;
  /* cursor: none; */
  text-decoration: none;
  color: black;
}

.txt {
  font-size: 20px;
  /* width: 80%; */
}

.avarineTxtCont {
  width: 60%;
  margin: 30px;
}

.avarinteTel {
  font-size: 50px;
  margin: 10px 0 0 0;
  /* font-weight: lighter; */
}

/*/////////////////////////*/

/*///////////GAUTAS ISKVIETIMAS/////////////*/

.iskvietimasGautasRight {
  border-radius: 20px;
  width: 50%;
  max-width: 90%;
  background-color: #f5b150 !important;
  /* display: flex; */
  /* flex-direction: column; */
  justify-content: flex-start;
}

.iskvietimasGautasContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  margin: 40px 0 40px 0;
}

.iskvietimasGautastxt {
  font-size: 20px;
}

.iskvietimasGautasDot {
  height: 130px;
  width: 130px;
  background-color: #727bdb !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*///////////////////////*/

/*////////////PASLAUGOS////////////*/

.pipes1 {
  display: flex;
  /* width: 100%; */
  position: relative;
  /* font-family: "Gilroy-Light"; */
  z-index: 2;
  /* height: 100vh; */
  /* background-image: url('kriaukle.png'); */
  /* object-fit: cover; */
}

.pipes1 img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.pipes4 {
  display: flex;
  /* width: 100%; */
  position: relative;
  /* font-family: "Gilroy-Light"; */
  z-index: 2;
  height: 170px;
  /* background-image: url('kriaukle.png'); */
  /* object-fit: cover; */
}

.pipes4 img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.pipes1V {
  display: flex;
  position: relative;
  z-index: 2;
  height: 500px;
  width: 100%;

}

.pipes1V img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 20px;
}

.pipes3 {
  display: flex;
  /* width: 100%; */
  position: relative;
  /* font-family: "Gilroy-Light"; */
  z-index: 2;
  height: 100vh;
}

.pipes3 img {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}

.pipes {
  /* display: flex; */
  width: 100%;
  position: relative;
  /* font-family: "Gilroy-Light"; */
}

.pipeContent {
  position: absolute;
  top: 8%;
  display: flex;
  right: 5%;
  justify-content: center;
  align-items: center;
}

.serviceName {
  /* font-size: 70px; */
  position: absolute;
  top: 6%;
  left: 20%;
  font-size: 4vw;
}

.servicesLeft {
  position: relative;
}

.servicesDotPos {
  position: absolute;
  right: 10%;
  top: 8%;
  margin: 0 0 0 70px;
}

.servicesDotTxt {
  text-align: center;
  font-size: 15px;
}

.servicesDot {
  height: 130px;
  width: 130px;
  background-color: #727bdb !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centerServicesContainer {
  display: flex;
  width: 100%;
  /* height: 100vh; */
}

.servicesRight {
  /* width: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  /* padding: 40px; */
  z-index: 1;
  width: 90%;
  background-image: url("assets/wire.png");
  background-color: var(--main-white);
}

.servicesMainTxt {
  font-size: 95%;
}

.servicesTxtCont {
  margin: 0 0 30px 0;
  max-width: 60ch;
}

/* Pelė */
.btnKviesti {
  border: 0px;
  border-radius: 40px;
  padding: 10px;
  width: 170px;
  font-size: large;
  background-color: #f5b150 !important;
  margin: 20px 0 0 0;
  position: relative;
  /* cursor: none; */
  color: black;
  text-decoration: none;
  text-align: center;
}
.btnKviesti2 {
  border: 0px;
  border-radius: 40px;
  padding: 10px;
  width: 170px;
  font-size: medium;
  background-color: #f5b150 !important;
  margin: 20px 0 0 0;
  position: relative;
  /* cursor: none; */
  color: black;
  text-decoration: none;
  text-align: center;
}
.btnKviesti3 {
  border: 0px;
  border-radius: 40px;
  padding: 10px;
  width: 170px;
  font-size: medium;
  background-color: #f5b150 !important;
  margin: 20px 0 ;
  position: relative;
  /* cursor: none; */
  color: black;
  text-decoration: none;
  text-align: center;
}
.prod_btn_container{
  width: 100%;
  justify-content: end;
  display: flex;
  position: absolute;
bottom: 60px;
right: 50px;
}

.servicesPrice {
  display: flex;
  /* width: 550px; */
  margin: 50px 0 0 0;
}

.servicesPriceLeft {
  background-color: #f0eee7 !important;
  border-radius: 10px;
  width: 200px;
  padding: 40px 20px 40px 20px;
  margin: 30px 30px 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.servicesPriceRight {
  margin: 30px 30px 30px 0;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.eur {
  font-size: 70px;
  font-weight: 500;
}

.eur2 {
  font-size: 25px;
  /* font-family: "Gilroy-Light"; */
  margin: 0 0 0 10px;
}

.fiksuotas {
  font-size: 14px;
  width: 170px;
}

.ieskote {
  display: flex;
  flex-direction: column;
}

.priceRightTxt {
  margin: 0 0 10px 0;
  font-size: 16px;
  text-decoration: none;
  color: black;
}

.servicesMainTxtCall {
  font-size: larger;
}

.wire {
  position: absolute;
  right: 7.5%;
}

.wire img {
  /* height: 60vw;
  width: 60vw; */
  height: 115%;
  width: 115%;
}

.wireMob {
  position: absolute;
  right: 0;
  bottom: 0;
}

.wireIr {
  position: absolute;
  left: 0;
  display: flex;
}

.wireIr img {
  height: 60vw;
  width: 60vw;
}

/*/////////////////////////*/

/*////////////PASLAUGOS -> IRENGIMAS////////////*/

.kriaukle {
  /* display: flex; */
  /* width: 50vw; */
  /* position: relative; */
}

.serevicesIrLeft {
  width: 79%;
  padding-bottom: 20px;
  z-index: 1;
  background-image: url("assets/wire.png");
  background-color: var(--main-white);
}

.servicePoints {
  margin: 30px 0 0 0;
}

.serviceIrTxt {
  font-size: 95%;
}

.punktas {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 0 0 0;
}

.vinis {
  margin: 0 15px 0 0;
}

.vinis1 {
  margin: 0 15px 0 0;
}

.vinisMob {
  display: none;
}

.servicesIrBotLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.servicesIrBotRight {
  background-color: #f0eee7 !important;
  border-radius: 10px;
  width: 200px;
  height: 150px;
  font-size: small;
  padding: 30px;
}

.serevicesIrBot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 50px 0 0 0;
}

.punktasG {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 20%;
  align-items: flex-start;
}

.neGrid>div {
  width: 100% !important;
}

.neGridFoot>div {
  width: unset !important;
}

.garantijaPoints {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* width: 50vw; */
  margin: 50px 0 0 0;
  font-size: large;
  z-index: 1;
}

.garantijaCont {
  display: flex;
  width: 100vw;
}

.garantija {
  background-color: #dc643e !important;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  position: relative;
}

.garantijaTxt {
  width: 60vw;
  text-align: center;
  font-size: larger;
  z-index: 1;
}

.irengimasCall {
  display: flex;
  flex-direction: column;
}

.garantijaImg {
  position: absolute;
  right: 0;
  height: 100%;
}

.garantijaName {
  z-index: 1;
}

/*/////////////////////////*/

/*////////////IRENGIMAS////////////*/

.irengimasRight {
  border-radius: 20px;
  width: 100%;
  max-width: 800px;
  background-color: #dc643e !important;
  /* display: flex; */
  /* flex-direction: column; */
  margin: 0 20px 40px 20px;
  position: relative;
}

.irengimasContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* margin: 20px 0 ; */
}

/* Pelė */
.irengimasBtn {
  border: 0px;
  border-radius: 40px;
  padding: 13px;
  width: 30%;
  font-size: medium;
  background-color: #f5b150 !important;
  margin: 50px 0 0 0;
  position: relative;
  /* cursor: none; */
  text-decoration: none;
  color: #000;
}

.irengimasTxt {
  font-size: 25px;
  /* width: 80%; */
}

.irengimasTxtCont {
  width: 60%;
  margin: 30px;
}

.irengimasTel {
  font-size: 30px;
  margin: 10px 0 0 0;
  text-decoration: none;
  color: black;
  /* font-weight: lighter; */
}

/*/////////////////////////*/

/*////////////SUGEDO////////////*/

.SantechnikaPng {
  position: absolute;
}

.sugedoRight {
  overflow: auto;
  max-height: 670px;
  border-radius: 20px;
  max-width: 800px;
  /* max-width: 80%; */
  /* margin: 20px; */
  background-color: #f5b150 !important;
  justify-content: center;
  /* max-width: 810px; */
  position: relative;
  width: 100% !important;
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
  margin: 0 20px 40px 20px;
}

.sugedoContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 40px 40px 40px;
  /* margin: 0 0 20px 0; */
}

/* Pelė */
.sugedoBtn {
  border: 1px solid black;
  background-color: unset !important;
  border-radius: 40px;
  padding: 13px;
  width: 100%;
  font-size: medium;
  margin: 40px 0 0 0;
  position: relative;
  /* cursor: none; */
  color: black;
}

.sugedoBtn:hover {
  border: 1px solid #f0eee7 !important;
  background-color: #f0eee7 !important;
}

.sugedoBtnCont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sugedoDot {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*/////////////////////////*/

/*////////////MĖNESIO NAUJIENA////////////*/
.newsContainer {
  display: flex;
  /* width: 50%; */
  flex-direction: row;
}

.newsContainer3 {
  display: flex;
  width: 20%;
  flex-direction: column;
  padding: 30px;
  
}

.product_photo img {
  width: 100%;
  /* object-fit: cover; */
  height: 100%;
}

.product_photo {
  display: flex;
  position: relative;
  z-index: 2;
  /* height: 200px; */
  width: 100%;
}

.produkas {
  background-color: white;
  border-radius: 20px;
  padding: 30px;
  /* width: 570px; */
  position: relative;
  height: fit-content;
  max-width: 40%;
  flex: 1 0 50%;
  /* min-height: 0; */
}

.produktas_container {

  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: 1fr 2fr; */
  column-gap: 50px;
  row-gap: 50px;
  /* max-width: 90%; */
  justify-content: center;
}

.newsContainerV {
  display: flex;
  width: 90%;
  flex-direction: row;
  margin: 50px 0;
}

.newsContainer:nth-child(even) {
  flex-flow: row-reverse wrap;
}

.eight {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}

.eight2 {
  position: absolute;
  right: 25%;
  transform: translateX(50%);
}

.eighttxt2 {
  font-size: 51vw;
  z-index: 0;
  color: white;
}

.eighttxt {
  font-size: 25vw;
  z-index: 0;
  color: #f0eee7;
}

.newsRight {
  /* z-index: 1; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  background-color: white;
}

.newsName {
  font-size: 3vw;
  margin: 5px 0;
}

.newsName2{
  font-size: 22px;
  margin: 20px 0!important;
}
.newsName3{
  font-size: 22px;
  margin: 50px 0 30px 0!important;
}

.newsNameCont {
  width: 30vw;
}

.newsTextCont {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 1;
}


.newsTextCont2 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 1;
  text-align: center;
  align-items: center;
}

.newsTextContV {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  z-index: 1;
}

.naujienaData {
  font-size: 15px;
  margin: 5px 0;

}

.naujienaData4 {
  font-size: 13px;
  margin: 5px 0;

}

.atidaryta_naujiena {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ql-align-justify {
  margin: 6px 0;
}

.naujienaPavadinimas {
  font-size: 25px;
  margin: 5px 0;
}

.naujienaTekstas {
  font-size: 15px;
}
.naujienaTekstas4 {
  font-size: 15px;
}

.naujienaTekstas2 {
  font-size: 15px;
}
.naujienaTekstas24 {
  font-size: 13px;
}

.naujienaTekstas_2 {
  font-size: 15px;
  /* line-height: 2; */
  margin: 30px 0!important;
}

.naujienaTekstas22 {
  font-size: 13px;
  margin: 30px 0!important;
  line-height: 2;
}


.naujienaTekstas22 ul {
  list-style: none;
  padding-left: 0;
}
.naujienaTekstas22 ul li {
  position: relative;
  padding-left: 36px;
}


.naujienaTekstas22 ul li:before{
  content: '';
  width: 30px;
  height: 10px;
  position: absolute;
  background-image: url('assets/vinis.png');
  background-size: cover;
  background-position: center;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}


/*/////////////////////////*/

/*////////////APIE////////////*/

@media screen and (max-width: 750px) {
  .aboutDotPos {
    display: none;
  }
}

.pipes22 {
  display: none;
}

.aboutMobBtnCont {
  display: none;
  color: black;
}

.aboutContainer {
  display: flex;
  width: 100%;
  position: relative;
}

.pipes2 {
  display: flex;
  /* width: 100%; */
  position: relative;
  /* font-family: "Gilroy-Light"; */
  /* height: 100vh; */
}

.pipes2 img {
  width: 100%;
  /* height: 100vh; */
  /* object-fit: cover; */
}

.contentContainerAbout {
  display: flex;
  height: 100%;
  width: 90%;
  margin: auto;
  max-width: 600px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutRight {
  background-color: #2f8b50 !important;
}

.aboutName {
  font-size: 220%;
}


.aboutTxt2 {
  font-size: 100%;
  line-height: 2;
}

.aboutDot {
  width: 144px;
  height: 144px;
  background-color: #db643e !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutDotPos {
  position: absolute;
  top: 70px;
  right: 70px;
}

.aboutDotTxt {
  width: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  font-size: 20px;
}

/*/////////////////////////*/

/*////////////NAUJIENLAISKIS////////////*/

.naujienlaiskis img {
  width: 100%;
}

.naujienlaiskis {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.naujienlaiskisCont {
  position: absolute;
  background-color: #f4b050 !important;
  z-index: 2;
  width: 45%;
  height: 13%;
  border-radius: 10vw;
  justify-content: center;
  display: flex;
  align-items: center;
}

.elpastas {
  background-color: unset !important;
  border: unset;
  border-bottom: 1px solid black;
  width: 85%;
  padding: 0.8vw;
  position: relative;
}

.elpastas:focus-visible {
  border: unset;
  border-bottom: 1px solid black;
  background-color: unset !important;
  outline: none;
}

input.elpastas::placeholder {
  color: black;
}

/* Pelė */
.naujienlaiskisBtn {
  background-color: unset !important;
  border: unset;
  position: absolute;
  right: 45px;
  /* cursor: none; */
  display: flex;
  flex-direction: row;
  color: black;
}

.naujienlaiskisBtnTxt {
  margin-right: 1vw;
  color: black;
}

/*/////////////////////////*/

/*///////////FOOTER//////////////*/

.footerText a {
  text-decoration: none !important;
  color: white;
}

.footerMob {
  display: none;
}

.navItemMob {
  color: #ffffff;
  text-decoration: none;
  margin: 10px 0 10px 0;
}

.footer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black !important;
}

.footerBackground {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.footerBackground img {
  width: 100%;
}

.footerContent {
  position: absolute;
  z-index: 2;
  color: white;
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.logoFooter img {
  width: 70%;
}

.footerMob.neGridFoot {
  display: none;
}

/*/////////////////////////*/

/*/////////ISKVIETIMAS//////////*/

.iskvietimasContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px;
}

.iskvietimasLeft {
  display: flex;
  flex-direction: column;
  width: 40%;
  /* justify-content: space-between; */
  align-items: center;
  margin: 0 20px 0 20px;
}

.iskvietimasRight {
  display: flex;
  flex-direction: column;
  width: 60%;
  /* justify-content: space-between; */
  align-items: center;
  margin: 0 20px 0 20px;
}

.iskvietimasProblema {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 15px 0;
  padding: 0 20px 0 0;
  width: 100%;
}

.problemaPhtBtn {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.problemaPhtTxt {
  text-align: left;
}

.problema_detaliau {
  border: 0px;
  border-radius: 30px;
  padding: 20px 10px 20px 20px;
  margin-top: 10px;
  background-color: #f0eee7 !important;
  width: 95% !important;
  height: 181px;
  resize: none;
  font-size: 16px;
}

.problema_detaliau:focus-visible {
  outline: none;
}

.problema_detaliau::placeholder {
  color: black !important;
  font-family: "Sora-Regular" !important;
}

/* Pelė */
.problemaBtn {
  margin: 6px 0;
  border-radius: 20px;
  padding: 4px;
  border: 1px solid black;
  background-color: #f4b050 !important;
  display: flex;
  /* cursor: none; */
  color: black;
}

.problemaBtn:hover {
  border: 1px #f0eee7;
  background-color: #f0eef0 !important;
  margin: 7px 0;
}

/* Pelė */
.problemaBtn .imgFull {
  width: 100%;
  background-color: transparent !important;
  border: none;
  cursor: none;
  color: black;
}

.problemaBtn .image-item.imgAdd {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: flex-start;
  padding: 0 10px;
  align-items: center;
}

/* Pelė */
.problemaBtn .imgAdd .opens {
  width: 90%;
  overflow: hidden;
  /* cursor: none; */
}

.problemaBtn .imgAdd .removes {
  width: 25%;
  text-align: center;
  /* overflow: hidden; */
}

.imgModal {
  position: absolute;
  top: 0;
  position: fixed !important;
  left: 0;
  background: #f0eee7;
  height: 100vh;
  width: 100vw;
  z-index: 99;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  display: flex;
  flex-direction: column-reverse;
}

.imgRemoveCont {
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: space-between;
  margin: 20px 0;
}

/* Pelė */
.removes {
  background-color: #f5b150 !important;
  padding: 15px 30px;
  border-radius: 40px;
  /* cursor: none; */
}

/* .removes1 {
  cursor: none;
} */

.imgModal img {
  /* background-color: #727bdb; */
  width: 30%;
  /* border: 50px solid #727bdb; */
  /* border-radius: 50px; */
  /* height: 90%; */
}

.img .iskvietimasInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.iskvietimasDataCont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 15px 0;
  align-items: flex-start;
  text-align: left;
}

.isvietimasData {
  width: 70%;
}

.iskvietimasLaikas {
  width: 30%;
  display: flex;
  flex-direction: column;
  height: 95%;
  justify-content: space-around;
  margin-top: 20px;
  font-size: 10px;
  margin-left: 5px;
}

/* Pelė */
.laikasBtn {
  border-radius: 20px;
  padding: 8px;
  border: 1px;
  border-color: #f4b050 !important;
  background-color: #f4b050 !important;
  border: 1px solid black;
  /* margin: -1px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* cursor: none; */
}

.laikasBtn.disabled {
  /* background-color: #000000; */
  color: #7a7a7a;
}

.laikasBtn.disabled:hover {
  border-color: #f4b050 !important;
  background-color: #f4b050 !important;
  border: 1px solid black;
}

.laikasBtn:hover {
  border: 1px solid #f0eee7 !important;
  background-color: #f0eee7 !important;
  /* margin: 1px; */
}

/* Pelė */
.laikasBtn:active {
  border: 1px solid black;
  /* margin: -1px; */
  background-color: #f4b050 !important;
  /* cursor: none; */
}

.laikasBtn.active {
  border: 1px solid #f0eee7 !important;
  /* margin:1px; */
  background-color: #f0eee7 !important;
}

.issikviestiBtnCont {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 15px;
  margin: 20px 0 0 0;
}

.issikviesti_bot_txt {
  font-size: 10px;
  text-align: left !important;
}

.issikviestiBotBtn {
  font-size: 16px;
  padding: 19px;
  background-color: #f0eee7 !important;
  border: 0;
  border-radius: 40px;
  color: black;
}

.atsauktiBotBtn {
  font-size: 16px;
  padding: 19px;
  background-color: var(--main-color) !important;
  border: 0;
  border-radius: 40px;
  color: var(--main-background);
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
}

.iskvietimas_kontaktai {
  width: 100%;
  border: 1px solid black;
  border-radius: 40px;
  padding: 19px 0 19px 20px;
  margin-top: 20px;
  width: 95%;
  background-color: #f4b050 !important;
  font-family: "Sora-Regular";
}

.iskvietimasInfo {
  width: 100%;
}

.PhoneInputCountry {
  display: none;
}

input.PhoneInputInput:focus-visible {
  outline: none;
}

input.PhoneInputInput::placeholder {
  color: black;
  opacity: 30%;
}

.PhoneInputInput {
  width: 100%;
  border: 1px solid black;
  border-radius: 40px;
  padding: 19px 0 19px 20px;
  margin-top: 20px;
  width: 95%;
  background-color: #f4b050 !important;
}

.iskvietimas_kontaktai::placeholder {
  color: black;
  opacity: 30%;
}

.iskvietimas_kontaktai:focus-visible {
  outline: none;
}

.calendarContainer {
  max-width: 318px;
  /* max-height: 205px; */
  border: 1px solid black;
  border-radius: 30px;
  padding: 10px;
  margin: 10px 0px 0 0;
}

.react-calendar__navigation {
  display: flex;
  margin-bottom: 3px;
  width: 289px;
}

.react-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-calendar__month-view__days {
  display: flex;
  justify-content: center;
  align-items: center;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  border: none !important;
  background-color: #f5b150 !important;
  padding: 13px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  flex: 0 0 12% !important;
  margin: -8px 3px 0 3px;
}

button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  z-index: 99;
}

button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background-color: whitesmoke !important;
  border-radius: 100%;
}

button.react-calendar__tile.react-calendar__month-view__days__day:hover {
  border: none !important;
  background-color: #ffffff !important;
  border-radius: 500px;
  padding: z;
  z-index: 99;
}

.span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  color: black;
}

abbr[title] {
  display: none;
}

button.react-calendar__navigation__label {
  border: none !important;
  background-color: #f5b150 !important;
  padding: 7px;
  color: black;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  border: none !important;
  background-color: #f5b150 !important;
  padding: 7px;
  color: black;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  border: none !important;
  background-color: #f5b150 !important;
  padding: 7px;
  color: black;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.iskvietimasPrice {
  display: flex;
  flex-direction: row;
  margin: 30px 0 30px 0;
}

.iskvietimasPriceMob {
  display: none;
}

.iskvietimasPriceLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #dc643e !important;
  border-radius: 20px;
  padding: 10px 20px 10px 20px;
  /* width: 150px; */
  width: 90px;
  /* height: 90px; */
}

.iskvietimasPriceLeftTxt {
  line-height: 0;
  margin: 0;
}

.iskvietimasPriceRight {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 0 0 20px;
  text-align: left;
  height: 100%;
  font-size: 12px;
}

.iskvietimasEur {
  font-size: 50px;
  margin: 0;
}

.problemaPhtCont {
  width: 100%;
  text-align: left;
}

.iskvietimasSmallName {
  font-size: 15px;
  margin: 0 0 0 5px;
  text-align: left;
}

.iskvContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 50px 20px 50px;
  /* margin: 40px 0 40px 0; */
}

/*/////////////////////////*/

.naujiena {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-around;
  background: antiquewhite;
  margin: 10px 20px;
}

.lentele.naujienos {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 50px 5%;

}

.naujiena img {
  height: 50px;
  object-fit: scale-down;
}

.naujiena>* {
  flex: 1 1;
}

.archyvasImg {
  width: 50%;
}


.archyvasImg3 {
  /* width: 50%; */
  /* padding: 20px; */

}

.archyvasImgV {
  width: 50%;
}

.archyveRightV {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 50px;
  background-color: var(--main-background) !important;
}

.archyveRight {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;
}


.archyveRight3 {
  display: flex;
  /* width: 50%; */
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;
  padding: 20px 0 20px 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.archyveContainer {
  display: flex;
  height: 100%;
  width: 90%;
  margin: auto;
  max-width: 600px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('assets/vinis2.png');
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-size: contain;
}

.archyveContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.sfCheck {
  display: flex;
  width: 100%;
  padding: 20px 0 0 10px;
  align-items: center;
}

.butina {
  font-size: 10px;
  display: flex;
  /* justify-content: flex-end; */
  padding: unset;
  margin: 5px 0 0 0;
}

/*kalendorius*/

.rmdp-calendar {
  background-color: #f5b150 !important;
  border: unset;
  outline: unset;
  color: black;
}

.rmdp-shadow {
  box-shadow: unset !important;
}

.rmdp-header-values {
  color: black !important;
}

.rmdp-day,
.rmdp-week-day {
  color: black !important;
}

.rmdp-day.rmdp-disabled {
  color: #7a7a7a !important;
}

.rmdp-day.rmdp-today span {
  background-color: var(--main-background) !important;
  color: var(--main-color) !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--main-background) !important;
  color: var(--main-color) !important;
  box-shadow: unset !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: unset !important;
  color: #fff !important;
  border: 1px solid var(--main-background) !important;
}

.rmdp-arrow-container.disabled .rmdp-arrow,
.rmdp-arrow-container.disabled:hover .rmdp-arrow {
  border: solid #7a7a7a !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow {
  border: solid black !important;
  border-width: 0 2px 2px 0 !important;
}

/* .rmdp-day span{
  color: black!important;
} */

.rmdp-day.rmdp-today span {
  background-color: var(--main-color) !important;
  color: var(--main-background) !important;
}

/*modal*/

.modal {
  /* height: 35vh; */
  /* width: 40%; */
  max-width: 90vw;
  margin: auto;
  /* transform: translate(0%, 30%); */
  background: var(--main-background);
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border-color: var(--main-background);
}

.closeBtn {
  position: absolute;
  top: 7.5px;
  right: 12px;
  background-color: transparent !important;
  border: none;
  color: var(--main-color);
  font-size: 20px;
  font-family: revert;
  cursor: pointer;
}

.modalInfo {
  overflow-y: auto;
  margin: 0 0px 30px 0px;
  font-size: 17px;
  width: 70%;
  text-align: center;
}

.ReactModal__Overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.centerContainer1 {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 40px;
}

.column1 {
  flex-direction: column;
}

input.patvirtinimoKodas {
  border-bottom: 1px solid #f5b150;
  box-shadow: unset;
  border-style: solid;
  border: unset;
  border-bottom: 2px solid #f5b150;
  background-color: var(--main-background) !important;
  padding: 7px 10px;
  margin: 10px;
  outline: none;
  text-align: center;
}

.rmdp-arrow-container:hover {
  background-color: unset !important;
  box-shadow: 0 0 3px #8798ad;
}

.patvirtinimas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Pelė */
.btnKviestihome {
  border: 0px;
  border-radius: 40px;
  padding: 10px;
  width: 170px;
  font-size: medium;
  background-color: #f5b150 !important;
  margin: 50px;
  position: relative;
  /* cursor: none; */
  color: black;
  text-decoration: unset;
}

.centerContainer1:focus-visible {
  outline: unset;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  color: var(--main-color) !important;
  background-color: var(--main-background) !important;
}

.removes1 {
  padding: 0 0 0 5px;
}

.politikaCont {
  padding: 40px;
}

.hidden {
  display: none;
}

.opener::after {
  content: url("drop1.png");
}

.opener.open::after {
  content: url("drop2.png");
}

a.issikviesti_bot_txt {
  color: black;
}

.privalomas {
  text-align: left;
}

.selectas {
  width: -webkit-fill-available;
  text-align: left;
}

.asmenstipas {
  width: 100%;
}

.react-select__control {
  border-radius: 40px !important;
  padding: 7px 10px !important;
  font-size: 11px;
}

.react-select__placeholder {
  color: #ab7c37 !important;
}

.react-select__menu-list {
  background-color: transparent !important;
  /* color: var(--main-background); */
  border-radius: 15px;
  border: 1px solid black;
  padding-top: unset !important;
  padding-bottom: unset !important;
}

.react-select__option--is-focused {
  color: black !important;
  background-color: var(--main-background) !important;
}

.react-select__menu {
  /* background-color: unset!important; */
  /* border-radius: 4px; */
  box-shadow: unset !important;
  position: unset !important;
  z-index: 1;
  box-sizing: border-box;
  color: unset !important;
  background: unset !important;
}

/* //11/9/2022 */

.autocomplete>div>label:not(:first-of-type),
.autocomplete button {
  display: none;
}

.autocomplete label {
  font-size: 0;
}

.laikrodisTick {
  margin-top: 10px;
}

.infoLentele {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.infoLentele>div {
  flex: 50%;
  margin: 5px 0;
}

.ManoUzsakymas {
  margin: auto;
  max-width: 489px;
}

.ManoUzsakymas h1 {
  text-align: center;
}

.center.manoUzsakymas {
  margin: 50px auto;
  width: fit-content;
}

* {
  scroll-margin-top: 97px;
}

#News {
  min-height: 100vh;
}


.papildomaAdreso {
  display: flex;
  flex-direction: row;
}

.butoMArgin {
  margin-right: 5px;
}

.aboutDot2 {
  width: 50px;
  height: 50px;
  background-color: #db643e !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid var(--main-background);
}

.aboutDotPos2 {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 100000;
}

.aboutDotTxt2 {
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  font-size: 20px;
  text-decoration: none;
  color: #000;
}

a.issikviestiBotBtn {
  text-decoration: none;
  margin-left: 10px;
}

.juodasTxt {
  color: #000;
}

.baltasTxt {
  color: #fff;
}

.avarineBeTarpoTxtTop {
  margin-top: unset;
}

.avarineBeTarpoTxtBot {
  margin-bottom: unset;
}

.issikviestiColumn {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.issikviestiRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

/* .sugedoHomePav {
  position: fixed;
  left: 0;
  width: 100vw;
  }

.sugedo{
  height: unset!important;
  position: relative;
  justify-content: end;
  padding: 0 20px 0 0;

} */
/* 
.paveikslelis{
  position: fixed;
  display: flex;
  left: 0;
}

.sugedoFix{
  margin: 0 20px 0 0;
} */

.popUpClose {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 20px;
}

.servicesMainTxtCall.juodasTxt {
  padding: 10px 0;
}

.garantija_points {
  margin: unset;
}

.sugedoRight::-webkit-scrollbar {
  display: none;
}

.sugedoRight {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* 
.centerContainer.sugedo {
  height: 100vh;
  } */

.iskvietimasContainer {
  padding: 40px 0px;
}

.laikasBtn {
  margin: 0px 10px;
  min-width: 90px;
}

.iskvietimasLaikas {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  height: 83%;
  justify-content: space-around;
  align-items: auto;
  margin-top: 20px;
  width: 30%;
}

.kaire {
  width: 40% !important;
  justify-content: center;
  display: flex;
  align-items: flex-end;
  height: 90vh;
}

.paveikslelis {
  padding-left: 10px;
}

.kaire1 {
  width: 40% !important;
  justify-content: center;
  display: flex;
  align-items: flex-end;
  height: 90vh;

}

.desine {
  width: 60%;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 90vh;
  /* padding: 0px 20px 20px 0px; */
}

img.mainPav {
  max-height: 90vh;
}

/* Paulius */
@media screen and (max-height: 700px) {
  .homeRightTop {
    font-size: 40px;
    font-weight: 500;
    position: relative;
    margin: 0px 0 0px 0;
    padding: 0px 0px 20px 18px;
  }

  .homeRightBottom>div {
    width: 190px !important;
    padding: 20px 20px 10px;
    max-height: 331px;
  }

  .text h2 {
    margin-top: 0;
  }

  .mainPavTxt {
    position: absolute;
    width: 10px;
    margin: unset;
    font-size: 9px;
  }

  img.mainPav {
    width: auto;
    max-width: 80%;
    max-height: 70vh;
  }

  .sugedoRight {
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-height: 90vh;
  }

}

.homeLeftPav {
  width: unset !important;
}

.sugedoRight {
  padding: unset !important;
  margin: unset !important;
}

.homeLeftPav {
  width: unset !important;
  max-width: unset !important;
}

.laikasBtn {
  border: 1px solid black !important;
}

.greiciau {
  z-index: 1;
  position: relative;
  margin-top: 17px;
}

.iskvietimasLeft {
  margin: 0px 17px 0px 0px;
}

.iskvietimasRight {
  margin: unset;
}

.iskvietimasProblema {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 15px 0;
  padding: 0px;
  width: 100%;
}

.problema_detaliau {
  margin: 0px;
  margin-top: 10px;
  background-color: #f0eee7 !important;
  width: 85% !important;
  height: 151px;
  resize: none;
  font-size: 16px;
  padding: 20px;
}

.laikasBtn {
  margin: 0px 0px;
  min-width: unset;
}

.laikasBtn.disabled:hover {
  border-color: #f4b050 !important;
  background-color: #f4b050 !important;
  border: 1px solid black !important;
}

.iskvietimasLaikas {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  height: 100%;
  justify-content: space-around;
  align-items: auto;
  margin-top: 15px;
  width: 30%;
}

.irengimasTxt {
  font-size: 23px;
}

img.mainPav {
  width: auto;
  max-width: 85%;
  height: auto;
  max-height: 90vh;
}

.desine {
  width: 60% !important;
}

.sugedoRight.sugedoFix {
  height: 90vh;
}

.irengimasRight {
  max-height: 80vh;
}

html[native-dark-active] .footerContent {
  color: var(--native-dark-font-color);
  filter: brightness(2.2);
  filter: invert(1);
}

html[native-dark-active] .logoFooter img {
  filter: invert(1);
}

html[native-dark-active] .column,
html[native-dark-active] .column2,
html[native-dark-active] .column3 {
  background-color: var(--native-dark-bg-color);
  filter: brightness(1.1);
  margin: 10px;
}

html[native-dark-active] .footerMob {
  background-color: rgb(226, 129, 109);
}

html[native-dark-active] .column {
  background-color: var(--native-dark-bg-color);
  filter: brightness(1.06);
}

html[native-dark-active][native-dark-active][native-dark-active] .servicesPriceLeft {
  background-color: var(--native-dark-bg-color) !important;
  filter: brightness(1.06);
}

html[native-dark-active] .serevicesIrLeft {
  background-image: linear-gradient(var(--native-dark-bg-image-color),
      var(--native-dark-bg-image-color)),
    url(http://localhost:3001/static/media/wire.cbf415c….png);
  background-color: var(--native-dark-bg-color);
  filter: brightness(1.06);
}

html[native-dark-active] .main_dot {
  background-color: rgb(34, 101, 58) !important;
  filter: brightness(1.06);
}

html[native-dark-active] .btnKviesti {
  color: var(--native-dark-font-color);
  background-color: var(--native-dark-bg-color) !important;
  filter: brightness(1.06);
}

html[native-dark-active][native-dark-active] .btnKviesti {
  background-color: var(--native-dark-bg-color) !important;
  filter: brightness(1);
}

html[native-dark-active][native-dark-active][native-dark-active] .btnKviesti {
  background-color: var(--native-dark-bg-color) !important;
  border: solid black 1px !important;
}

html[native-dark-active] .sugedoRight {
  background-color: var(--native-dark-bg-color) !important;
  /* filter: brightness(1.1); */
  border: solid black 1px !important;
}

.servicesRight {
  padding: 40px 0px;
}

.serevicesIrLeft {
  padding-bottom: 60px;
}

.problema_detaliau {
  line-height: 22px;
}

a {
  cursor: pointer;
}

p {
  cursor: default;
}

/* 
ol {
  counter-reset: item;
}
li {
  display: block;
}

.mainlist li{
  display: flex;
  align-items: center;
}

li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
  display: inline-block;
  margin-right: 10px;
} */

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol>li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol>li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol>li {
  margin: 0;
}

li ol>li:before {
  content: counters(item, ".") " ";
}

.marginTop {
  margin-top: 20px;
}

.pointer {
  cursor: pointer !important;
}

p:blank{
  display: none;
}